import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import { Link } from "gatsby"


import Header from "./header"
import 'typeface-roboto-slab'
import 'typeface-montserrat'
import 'typeface-roboto'
import "./preflight.css"
import "./main.css"
import Menu from 'react-burger-menu/lib/menus/slide'
import Logo from "../images/ffmlogo.inline.svg"
import Facebook from "../images/icon-facebook.inline.svg"
import Instagram from "../images/icon-instagram.inline.svg"
import Twitter from "../images/icon-twitter.inline.svg"
import Footer from "./footer"

const Main = styled.main`
`

const SideMenu = styled.div`

  .bm-menu-wrap {
    top: 0;
  }

  .bm-item {
    /* display: inline-block; */
    /* Our sidebar item styling */
    text-decoration: none;
    font-family: "Roboto Slab", serif;
    font-size: 20px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    a {
      margin-bottom: 1em;
      color: #fcfcfc;
      transition: color 0.2s;
      &:hover {
        color: #fdf5bf;
        transition: color 0.2s;
      }
    }

  }
  /* Change color on hover */
  .bm-item:hover {
    /* color: #fdf5bf; */
  }
  /* The rest copied directly from react-burger-menu docs */
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 2em;
    top: 2.75em;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-overlay {
    top: 0;
  }
  .bm-menu {
    background: #013057;
    padding: 3em 2em 0;
    /* font-size: 1.25em; */
    box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07)
    ;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    display: flex;
    flex-direction: column;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 1024px) {
    display: none;
  }

`
const LogoWrapper = styled.div`
  margin: 0 auto 2em;

  svg {
    width: 140px;
  }
`

const SocialLinks = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around;
  margin-bottom: 2em;

  svg {
    width: 25px;
    fill: rgba(255,255,255,0.5);
  }
`

const NavMenu = styled.div`
  display: flex !important;
  flex-direction: column !important;
  flex: 1 !important;

  a {
    margin-bottom: 1.5em;
    color: #fcfcfc;
    transition: color 0.2s;

    &:hover {
      color: #fdf5bf;
      transition: color 0.2s;
    }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div id="outer-container">
        <Header siteTitle={data.site.siteMetadata.title} />
        <SideMenu>
          <Menu disableAutoFocus right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <NavMenu>
              <Link to="/about">About the Market</Link>
              <Link to="/whatsfresh">Fresh at Fairview</Link>
              <Link to="/visit">Preparing for the Market</Link>
              <Link to="/vendors">Vendor Directory</Link>
              <Link to="/growers">For Growers & Vendors</Link>
            </NavMenu>
            <SocialLinks style={{justifySelf: "flex-end"}}>
              <Instagram />
              <Facebook />
              <Twitter />
            </SocialLinks>
          </Menu>
        </SideMenu>
        <Main id="page-wrap">{children}</Main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
