import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Logo from '../images/logov6.inline.svg'
import HeartIcon from '../images/icon-heart.inline.svg'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const HeaderWrapper = styled.header`
  height: 110px;
  border-bottom: 2px solid #540B0E;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07)
  ;

  @media (min-width: 1200px) {
    height: 120px;
  }
`

const LogoWrapper = styled(Link)`
  height: auto;
  width: 155px;
  z-index: 3;
  margin-top: 1em;

  @media (min-width: 1024px) {
    height: auto;
    width: 165px;
    margin-right: 3em;
  }

  @media (min-width: 1200px) {
    height: auto;
    width: 165px;
    margin-right: 4em;
  }

  @media (min-width: 1600px) {
    margin-right: 6em;
  }
`

const NavWrapper = styled.div`
  display: flex;
  alignItems: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2em;
  justify-content: center;
  width: 100%;

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`
const NavMenu = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-top: -1.5em;

  @media (min-width: 1024px) {
    display: flex;
  }
`

const NavItem = styled(Link)`
  color: #f9f9f9;
  font-family: "Roboto Slab", serif;
  font-size: 15px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  line-height: 112%;
  letter-spacing: .75px;
  transition: color .25s ease-in-out;
  text-shadow: 0 0 30px rgba(0,0,0,.3);

  @media (min-width: 1100px) {
    font-size: 16px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }

  &:hover {
    color: #FDF5BF;
    transition: color .25s ease-in-out;
  }
`

const CtaButton = styled(Link)`
  color: #fcfcfc;
  font-family: "Roboto Slab", serif;
  font-size: 14px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  padding: .5em 1.1em;
  border-radius: 2px;
  border: 2px solid #fcfcfc;
  letter-spacing: .75px;
  transition: background .3s ease, color .3s ease, border .3s ease;
  display: flex;
  align-items: center;

  @media (min-width: 1100px) {
    font-size: 15px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }

  &:hover {
    background: #fcfcfc;
    color: #F32735;
    border: 2px solid #fcfcfc;
    transition: background .3s ease, color .3s ease, border .3s ease, box-shadow .3s ease;
    box-shadow:
      0 2.8px 2.2px -41px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px -41px rgba(0, 0, 0, 0.028),
      0 12.5px 10px -41px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px -41px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px -41px rgba(0, 0, 0, 0.05),
      0 100px 80px -41px rgba(0, 0, 0, 0.07)
    ;

    svg {
      fill: #F32735;
      transition: fill .3s ease;
    }
  }

  svg {
    height: 20px;
    width: auto;
    fill: white;
    margin-right: .5em;
    transition: fill .3s ease;

    @media (min-width: 1200px) {
      height: 24px;
    }
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "rustbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.placeholderImage.childImageSharp.fluid}
      style={{ backgroundColor: "#f32735", width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "center"}}
      >
      <HeaderWrapper>
        <NavWrapper>
          <LogoWrapper to="/">
            <Logo />
          </LogoWrapper>
          <NavMenu>
            <NavItem to="/about">
              About the<br/>
              Market
            </NavItem>
            <NavItem to="/whatsfresh">
              What's Fresh<br/>
              at Fairview
            </NavItem>
            <NavItem to="/visit">
              Preparing for<br/>
              the Market
            </NavItem>
            <NavItem to="/vendors">
              Vendor<br/>
              Directory
            </NavItem>
            <NavItem to="/growers">
              For Growers<br/>
              & Vendors
            </NavItem>
            <CtaButton to="/donate">
              <HeartIcon />
              Donate Now
            </CtaButton>
          </NavMenu>
        </NavWrapper>
      </HeaderWrapper>
    </BackgroundImage>
  )
}

export default Header
