import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import Logo from "../images/ffmlogo.inline.svg"
import Twitter from "../images/icon-twitter.inline.svg"
import Facebook from "../images/icon-facebook.inline.svg"
import Instagram from "../images/icon-instagram.inline.svg"

const Wrapper = styled.div`
  width: 100%;
`

const FooterWrap = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 6em 2em;
  display: flex;
  flex-direction: column;

  color: rgba(255,255,255,.5);

  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  /* -webkit-font-smoothing: antialiased; */
  font-size: 12px;
  letter-spacing: 2px;

  ul > li {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul > li > a {
    transition: color .3s ease;



    :hover {
      color: #fefefe;
      transition: color .3s ease;
    }
  }


`

const SocialIcons = styled.div`
  max-width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;

  svg {
    width: 30px;
    height: auto;
    margin: 0 2em;
    fill: rgba(255,255,255,.5);
    transition: fill .3s ease;

    &:hover {
      fill: #fefefe;
      transition: fill .3s ease;
    }
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: flex-end;
  }
`

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 4em;
`

const LogoWrapper = styled(Link)`

  svg {
    width: 160px;
    fill: white;
    margin-bottom: 1em;
  }

  color: rgba(255,255,255,0.8);
  font-size: 13px;
  font-family: "Roboto", serif;

  @media (max-width: 900px) {
    order: 1;
  }
`

const Left = styled.div`
  flex: 1;
  text-align: center;

  @media (max-width: 900px) {
    order: 2;
    margin: 1.5em 0;
  }
`

const Right = styled.div`
  flex: 1;
  text-align: center;
  @media (max-width: 900px) {
    order: 3;
  }
`

const Copyright = styled.div`
  text-align: center;
  margin-top: 4em;
  -webkit-font-smoothing: antialiased;
  font-size: 11px;
  color: rgba(255,255,255,.25);
`

const FooterWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "footerbg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.placeholderImage.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`,
  ].reverse()

  return (
    <Wrapper>
      <BackgroundImage
        fluid={backgroundFluidImageStack}
        style={{width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "center"}}
      >
          <FooterWrap>
            <Top>
              <Left>
                <ul>
                  <li><Link to="/about">About the Market</Link></li>
                  <li><Link to="/whatsfresh">What's Fresh at Fairview</Link></li>
                  <li><Link to="/visit">Preparing for the Market</Link></li>
                </ul>
              </Left>
              <LogoWrapper to="/">
                <Logo />
              </LogoWrapper>
              <Right>
                <ul>
                  <li><Link to="/vendors">Vendor Directory</Link></li>
                  <li><Link to="/growers">For Growers & Vendors</Link></li>
                  <li><Link to="/donate">Donate to FFM</Link></li>
                </ul>
              </Right>
            </Top>
            <Bottom>
              <SocialIcons>
                <a href="https://instagram.com" alt="Instagram"><Instagram /></a>
                <a href="https://facebook.com" alt="Facebook"><Facebook /></a>
                <a href="https://twitter.com" alt="Twitter"><Twitter /></a>
              </SocialIcons>
            </Bottom>
            <Copyright>
              &copy;2020 Fairview Farmers Market. All rights reserved.<br/>Website by <a target="_blank" rel="noreferrer" href="https://thirddogdigital.com">Third Dog Digital</a>
            </Copyright>
          </FooterWrap>
      </BackgroundImage>
    </Wrapper>
  )
}

export default FooterWrapper
